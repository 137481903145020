import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  padding: 100px 20px;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 60px;
  text-align: center;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  list-style: none;
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #acb5cc;
    margin-bottom: 30px;
  }

  .primary-color-text {
    color: var(--primary-color);
    font-weight: bold;
  }

  .highlight-color-text {
    color: var(--highlight-color);
    font-weight: 580;
  }
`;


const About = () => {
  return (
    <AboutSection id="about">
      <Heading>About Me</Heading>
      <Content>
      <p>
        Hi, I'm Sofya (Sonya). I'm a second-year bachelor student majoring in 
        <span className="highlight-color-text"> Mathematics</span> and <span className="highlight-color-text">Computer Science</span>, 
        with a minor in Biology. Recently, I've been interested in <span className="highlight-color-text">machine learning</span> and <span className="highlight-color-text">computer vision</span> for <span className="highlight-color-text">video editing</span> and <span className="highlight-color-text">processing</span>, and wish to learn more about that.
        I enjoy combining <span className="highlight-color-text">science</span> and <span className="highlight-color-text">creativity</span> in meaningful ways.
      </p>
      <p>
        In my free time, I enjoy <span className="highlight-color-text">bouldering</span>, <span className="highlight-color-text">reading</span>, and exploring <span className="highlight-color-text">digital design</span>, 
        particularly <span className="highlight-color-text">poster creation</span> and <span className="highlight-color-text">video editing</span>.
      </p>
    </Content>
    </AboutSection>
  );
};

export default About;