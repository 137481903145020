import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import MouseParticlesComponent from './components/MouseParticlesComponent';
import { GlobalStyles } from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Gallery from './components/Gallery';


// Style for the glowing line that breaks between sections
const LineBetweenSections = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 100px;
  background-color: #64ffda;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  box-shadow: 0 0 20px rgba(100, 255, 218, 0.6), 0 0 40px rgba(100, 255, 218, 0.4), 0 0 60px rgba(100, 255, 218, 0.2);
`;

// Section wrapper with padding to create gaps between containers and line breaks
const SectionWrapper = styled.section`
  position: relative;
  padding: 30px 0;
  opacity: ${(props) => (props.isVisible ? 1 : 0.2)};
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 150px;
    background-color: #64ffda;
    box-shadow: 0 0 20px rgba(100, 255, 218, 0.6), 0 0 40px rgba(100, 255, 218, 0.4), 0 0 60px rgba(100, 255, 218, 0.2);
  }
`;



function App() {
  const [sectionVisibility, setSectionVisibility] = useState({
    about: false,
    experience: false,
    projects: false,
    contact: false,
    gallery: false, // Added gallery visibility tracking
  });

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['about', 'experience', 'projects', 'contact', 'gallery'];
      const newVisibility = { ...sectionVisibility };
  
      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;
  
          // Check gallery visibility earlier (closer to the bottom of the screen)
          if (section === 'gallery') {
            newVisibility[section] = rect.top < windowHeight * 0.5 && rect.bottom > windowHeight * 0.5;
          } else {
            newVisibility[section] = rect.top < windowHeight / 2 && rect.bottom > windowHeight / 2;
          }
        }
      });
  
      setSectionVisibility(newVisibility);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionVisibility]);

  return (
    <>
      <Helmet>
        <title>Sofya Klyuchereva</title>
        <meta name="description" content="Personal portfolio website." />
      </Helmet>

      <GlobalStyles />
      <Navbar />

      <main>
        <Home />
        <SectionWrapper id="about" isVisible={sectionVisibility.about}>
          <About />
          <LineBetweenSections isVisible={sectionVisibility.about} />
        </SectionWrapper>
        <SectionWrapper id="experience" isVisible={sectionVisibility.experience}>
          <Experience />
          <LineBetweenSections isVisible={sectionVisibility.experience} />
        </SectionWrapper>
        <SectionWrapper id="projects" isVisible={sectionVisibility.projects}>
          <Projects />
          <LineBetweenSections isVisible={sectionVisibility.projects} />
        </SectionWrapper>
        <SectionWrapper id="contact" isVisible={sectionVisibility.contact}>
          <Contact />
        </SectionWrapper>
        <SectionWrapper id="gallery" isVisible={sectionVisibility.gallery}>
          <Gallery />
        </SectionWrapper>
      </main>

      <Footer />
      <MouseParticlesComponent />
    </>
  );
}

export default App;
